import React, { useState, useRef } from 'react';
import "../css/ontology.css";
import ontology from "../assets/banner-visuels/Fluid.png";
import dropdownIcon from "../assets/banner-visuels/Icon.png";

function Ontology() {
  const [visibleBlock, setVisibleBlock] = useState(null);
  const contentRefs = useRef([]);

  const toggleVisibility = (index) => {
    setVisibleBlock(visibleBlock === index ? null : index);
  };

  const hardcodedData = [
    {
      title: "Customer Data Platform",
      content: "We unify your customer data into a single view, enabling targeted marketing and personalized experiences. This drives customer satisfaction, loyalty, and a higher return on your marketing efforts."
    },
    {
      title: "Journeys",
      content: "By optimizing every step of your customer journey, we help you deliver seamless, high-quality experiences that increase customer conversion, satisfaction, and retention."
    },
    {
      title: "Data",
      content: "We help you build a strong data foundation, ensuring your data is high-quality, secure, and easily accessible. This sets you up for accurate insights and effective, data-driven decisions across your organization."
    },
    {
      title: "Models",
      content: "With artificial intelligence and machine learning solutions tailored to your industry, we turn your raw data into valuable insights that drive operational efficiency, cut costs, and unlock new growth opportunities."
    },
    {
      title: "Analytics",
      content: "Our analytics services make your data work for you, empowering your team with clear, actionable insights that support strategic decision-making and a competitive edge in your market."
    },
    {
      title: "Integrations",
      content: "We ensure all your systems work together seamlessly, providing real-time access to insights and capabilities across your organization. This boosts efficiency and enables quick, informed responses to market demands."
    }
  ];

  return (
    <div className='Ontology'>
      <div className='container'>
        <img src={ontology} className='ontology-logo' alt="Ontology Logo" />
      </div>
      <div className='container'>
        <div className='ontology--information'>
          {hardcodedData.map((item, index) => (
            <div
              className='information-block'
              key={index}
              onClick={() => toggleVisibility(index)}
            >
              <div className='information-block--title'>
                {item.title}
                <span
                  className={`information-block--icon ${visibleBlock === index ? 'rotate' : ''}`}
                >
                  <img src={dropdownIcon} alt="Dropdown Icon" />
                </span>
              </div>
              <div
                className='information-block--text'
                ref={(el) => (contentRefs.current[index] = el)}
                style={{
                  height: visibleBlock === index
                    ? `${contentRefs.current[index]?.scrollHeight || 0}px`
                    : '0',
                  overflow: 'hidden',
                  transition: 'height 0.5s ease'
                }}
              >
                {item.content}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Ontology;
